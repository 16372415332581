import { useEffect, useState } from "react";
import { Table, Modal, Row, Col, Button, Alert, Input, Tag, message } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";

import "./video.scss";
import {
  createVideo,
  editVideo,
  deleteVideo,
  getVideos,
  createMuxURLUpload,
  getMuxAssetByUploadId,
  getMuxPlaybackUrl,
} from "../../store/actions/videoActions";
import DeleteForm from "./components/DeleteForm";
import VideoForm from "./components/VideoForm";
import { uploadOtherFilesToCloudflare } from "../../utils/uploadFileToCloudflare";
import { fetchPdfs } from "../../store/actions/pdfsActions";
import { fetchTags } from "../../store/actions/tagsActions";
import { DeleteOutlined, EditOutlined, LinkOutlined } from "@ant-design/icons";
import { fetchTracks, searchTrack } from "../../store/actions/trackActions";
import { storage } from "../../config/firebase";

const { Search } = Input;

// eslint-disable-next-line
const Videos = (props) => {
  const {
    createVideo,
    editVideo,
    fetchPdfs,
    fetchTags,
    fetchTracks,
    pdfs,
    tags,
    getVideos,
    deleteVideo,
    videos,
    tracks,
    error,
    user,
    searchTrack,
    searchResult,
  } = props;
  const userLevels = ["admin", "specialist", "sales", "editor"];
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videoUploadPayload, setVideoUploadPayload] = useState({
    videoUploadError: null,
    videoUploadProgress: 0,
    videoUpload: null,
    videoURL: null,
    isVideoUploadInProgress: false,
  });
  const [difficulty, setDifficulty] = useState("");
  const [name, setName] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [videoId, setVideoId] = useState("");
  const [description, setDescription] = useState("");
  const [mp4url, setMp4url] = useState("");
  const [time, setTime] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [videoDBID, setVideoDBID] = useState("");
  const [pdfIds, setPdfIds] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    name: "Create Video",
    confirmBtnText: "Create",
    type: "createVideo",
  });
  const [muxUrl, setMuxUrl] = useState("");
  const [uploadId, setUploadId] = useState("");

  useEffect(() => {
    getVideos();
    fetchPdfs();
    fetchTags();
    fetchTracks();
  }, [getVideos, fetchPdfs, fetchTags, fetchTracks]);

  useEffect(() => {
    if (searchResult && searchText) {
      setSearchedResults(searchResult.videos || []);
    }

    if (!searchText || searchText === "") {
      setSearchedResults([]);
    }
  }, [searchResult, searchText]);

  const openModal = async (modalMetadata, record) => {
    if (modalMetadata.type === "createLocalUploadVideo") {
      await handleUploadSelect();
    }
    setModalInfo(modalMetadata);
    setIsModalVisible(true);
    if (record) {
      setName(record ? record.name : "");
      setDifficulty(record ? record.difficulty : "");
      setDescription(record ? record.description : "");
      setMp4url(record ? record.mp4Url : "");
      setVideoLink(record ? record["path"] : "");
      setTagIds(record.tags ? record.tags.map((tag) => tag.name) : []);
      setThumbnailURL(record ? record.thumbnail : "");
      setPdfIds(record.pdfs ? record.pdfs.map((tag) => tag.id) : []);
      setVideoDBID(record ? record.id : "");
      setTime(record ? record.time : "");

      setVideoId(record ? record.videoId : "");
      setVideoUploadPayload({
        ...videoUploadPayload,
        videoURL: record ? record["path"] : "",
      });
    }
  };

  const handleInputChange = (e, type) => {
    if (type === "name") {
      setName(e.target.value);
    }

    if (type === "time") {
      setTime(e.target.value);
    }

    if (type === "description") {
      setDescription(e.target.value);
    }

    if (type === "videoId") {
      setVideoId(e.target.value);
    }
  };

  const getFormattedUrl = (videoId) => {
    if (!videoId) return ""; 

    if (videoId.length === 11 && /^[a-zA-Z0-9_-]{11}$/.test(videoId)) {
      return `https://www.youtube.com/watch?v=${videoId}`; 
    }

    if (videoId.includes("youtube.com/watch?v=")) {
      const videoIdFromUrl = videoId.split("v=")[1]?.split("&")[0];
      return `https://www.youtube.com/watch?v=${videoIdFromUrl}`;  
    }

    if (videoId.includes("youtu.be/")) {
      const videoIdFromUrl = videoId.split("youtu.be/")[1];
      return `https://www.youtube.com/watch?v=${videoIdFromUrl}`;  
    }

    if (videoId.includes("/live/")) {
      const videoIdFromUrl = videoId.split("/live/")[1];
      return `https://www.youtube.com/watch?v=${videoIdFromUrl}`;  
    }

    return "";
  };

  const handleUploadSelect = async (uploadType) => {
    const uploadData = await createMuxURLUpload();
    setMuxUrl(uploadData.url);
    setModalInfo({
      name: "Upload video from local computer",
      confirmBtnText: "Create",
      type: "createLocalUploadVideo",
    });
    setUploadId(uploadData.id);
  };

  const customUpload = async ({ onError, onSuccess, file }) => {
    const metadata = {
      contentType: "video/mp4",
    };
    const storageRef = await storage.ref();
    const name = file.name;
    const vfile = storageRef.child(`videos/${name}_${+new Date()}.mp4`);
    try {
      const video = uploadBytesResumable(vfile, file, metadata);
      video.on(
        "state_changed",
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setVideoUploadPayload((prevState) => ({
            ...prevState,
            videoUpload: file,
            videoUploadProgress: Math.trunc(progress),
          }));
        },
        function (error) {
          // Handle unsuccessful uploads
        },
        async function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          const url = await getDownloadURL(video.snapshot.ref);
          setVideoUploadPayload((prevState) => ({
            ...prevState,
            videoUpload: file,
            videoUploadProgress: 100,
            videoURL: url,
          }));
        },
      );
      onSuccess(null, file);
    } catch (e) {
      onError(e);
    }
  };

  const handleSelectChanger = (value, selectType) => {
    if (selectType === "isPdfs") {
      setPdfIds(value);
    }

    if (selectType === "tags") {
      setTagIds(value);
    }

    if (selectType === "difficulty") {
      setDifficulty(value);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearState();
  };

  const clearState = () => {
    setName("");
    setDifficulty("");
    setVideoId("");
    setPdfIds([]);
    setTagIds([]);
    setThumbnailURL("");
    setThumbnail("");
    setTime("");
    setVideoLink("");
    setDescription("");
    setVideoDBID("");
    setVideoUploadPayload({
      videoUploadError: null,
      videoUploadProgress: 0,
      videoUpload: null,
      videoURL: null,
      isVideoUploadInProgress: false,
    });
    setMp4url("");
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const changeThumbnail = (file) => {
    getBase64(file.file.originFileObj, (imageUrl) => {
      setThumbnail(file.file);
      setThumbnailURL(imageUrl);
    });
  };

  const handleOk = async () => {
    setLoading(true);

    const payload = {
      name,
      videoId: getFormattedUrl(videoId),
      time,
      thumbnail: thumbnailURL,
      description,
      tags: tagIds,
      difficulty,
      pdfs: pdfIds,
      uploadType: "localUpload",
    };

    if (modalInfo.type === "createLocalUploadVideo") {
      if (thumbnail) {
        let thumbFileURL = await uploadOtherFilesToCloudflare(
          thumbnail.originFileObj,
          "thumbnails",
        );
        setThumbnailURL(thumbFileURL);

        if (
          name.length > 0 &&
          description.length > 0 &&
          thumbFileURL.length > 0
        ) {
          const assetData = await getMuxAssetByUploadId(uploadId);
          const playbackData = await getMuxPlaybackUrl(assetData.asset_id);
          await createVideo({
            ...payload,
            thumbnail: thumbFileURL,
            path: videoUploadPayload.videoURL,
            uploadId,
            assetId: assetData.asset_id,
            hlsUrl: playbackData.hlsUrl,
          });

          clearState();
          setLoading(false);
          setIsModalVisible(false);
        }
      }
    }

    if (modalInfo.type === "editLocalVideoUpload") {
      if (thumbnail) {
        let thumbFileURL = await uploadOtherFilesToCloudflare(
          thumbnail.originFileObj,
          "thumbnails",
        );
        setThumbnailURL(thumbFileURL);
        payload.thumbnail = thumbFileURL;
      }

      await editVideo(
        { ...payload, path: videoUploadPayload.videoURL },
        videoDBID,
      );
      clearState();
      setLoading(false);
      setIsModalVisible(false);
    }

    if (modalInfo.type === "deleteVideo") {
      await deleteVideo(videoDBID);
    }

    setLoading(false);
    await getVideos();
  };

  const onSearch = async (value) => {
    if (value) {
      await searchTrack(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleCopyLink = (record) => {
    let videoDetails = null; 

    tracks.some((track) => {
      const module = track.modules.find((module) =>
        module.videos.some((video) => video.id === record.id) 
      );

      if (module) {
        videoDetails = {
          trackName: track.slug,
          trackId: track.id,
          moduleName: module.slug,
        };
        return true;
      }
      return false;
    });

    if (!videoDetails) {
      message.error("Link não encontrado para este vídeo.");
      return;
    }

    const link = `https://app.paradigma.education/trilhas/${videoDetails.trackName}/${videoDetails.moduleName}/${record.slug}?videoTrack=${videoDetails.trackId}&id=${record.id}`
    
   
    navigator.clipboard.writeText(link).then(() => {
      setTimeout(() => 4000);
      message.success("Link copiado com sucesso!");
    }).catch(() => {
      message.error("Error ao copiar o link.")
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Difficulty",
      dataIndex: "difficulty",
      key: "difficulty",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.difficulty.localeCompare(b.difficulty),
    },
    {
      title: "Transcoding",
      dataIndex: "transcoding",
      key: "transcoding",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        const aValue = a.transcoding ? 1 : 0;
        const bValue = b.transcoding ? 1 : 0;
        return aValue - bValue;
      },
      render: (_, record) => (
        <Tag color={record.is_ready === true ? "green" : "red"}>
          {`${!record.is_ready}`}
        </Tag>
      ),
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (_, record) => (
        <div>{moment(record?.createdAt).format("DD/MMM/YYYY")}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            {record.createdBy === user.email ||
            userLevels.includes(user.clearanceLevel) ? (
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    openModal(
                      {
                        name: "Edit video",
                        confirmBtnText: "Update",
                        type: "editLocalVideoUpload",
                      },
                      record,
                    )
                  }
                >
                  <EditOutlined />
                </Button>
              </Col>
            ) : null}

            {user.clearanceLevel === "admin" ? (
              <Col>
                <Button
                  danger
                  onClick={() =>
                    openModal(
                      {
                        name: `Delete this video(${record.name})`,
                        confirmBtnText: "Delete",
                        type: "deleteVideo",
                      },
                      record,
                    )
                  }
                >
                  <DeleteOutlined />
                </Button>
              </Col>
            ) : null}

            {user.clearanceLevel === "admin" ? (
              <Col>
                <Button onClick={() => handleCopyLink(record)}>
                  <LinkOutlined />
                </Button>
              </Col>
            ) : null}
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <Row gutter={4}>
          <Col>
            {userLevels.includes(user.clearanceLevel) ? (
              <Button
                type="primary"
                onClick={() =>
                  openModal({
                    name: "Select type of upload",
                    confirmBtnText: "Confirm",
                    type: "createLocalUploadVideo",
                  })
                }
              >
                Create Video
              </Button>
            ) : null}
          </Col>

          <Col>
            <Search
              value={searchText}
              placeholder="Search here.."
              onSearch={onSearch}
              style={{ width: 200 }}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
      </div>

      {error ? (
        <div style={{ marginTop: 20 }}>
          {" "}
          <Alert message={error} type="error" />{" "}
        </div>
      ) : (
        ""
      )}

      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={
            searchText && searchedResults.length > 0
              ? searchedResults
              : Array.isArray(videos)
                ? videos
                : []
          }
        />
      </div>

      <Modal
        title={modalInfo.name}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger={modalInfo.type === "deleteVideo"}
            loading={loading}
            onClick={handleOk}
          >
            {modalInfo.confirmBtnText}
          </Button>,
        ]}
      >
        {modalInfo.type === "createLocalUploadVideo" ||
        modalInfo.type === "editLocalVideoUpload" ? (
          <VideoForm
            difficulty={difficulty}
            name={name}
            tags={tags}
            tagIds={tagIds}
            videoLink={videoLink}
            videoId={videoId}
            time={time}
            thumbnail={thumbnail}
            handleInputChange={handleInputChange}
            changeThumbnail={changeThumbnail}
            thumbnailURL={thumbnailURL}
            handleSelectChanger={handleSelectChanger}
            pdfIds={pdfIds}
            pdfs={pdfs}
            videos={videos || []}
            customUpload={customUpload}
            videoUploadPayload={videoUploadPayload}
            description={description}
            mp4url={mp4url}
            muxUrl={muxUrl}
          />
        ) : null}
        {modalInfo.type === "deleteVideo" ? <DeleteForm name={name} /> : null}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  response: state.videos.video || [],
  videos: state.videos.videos || [],
  tracks: state.tracks.tracks,
  error: state.videos.error,
  pdfs: state.pdfs.pdfs,
  tags: state.tags.tags,
  searchResult: state.tracks.searchResult,
  searchError: state.tracks.searchError,
});

export default connect(mapStateToProps, {
  createVideo,
  editVideo,
  deleteVideo,
  getVideos,
  fetchPdfs,
  fetchTracks,
  fetchTags,
  searchTrack,
})(Videos);
