import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Table, Pagination, Spin, message } from "antd";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { fetchFeedback } from "../../store/actions/feedbackActions";
import ViewForm from "./ViewForm";
import { SmileOutlined } from "@ant-design/icons";
import axios from "axios";

const Feedback = ({ feedback, fetchFeedback }) => {
  const [csvData, setCsvData] = useState("");
  const [csvLink, setCsvLink] = useState(null);
  const [formError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [type, setType] = useState("viewFeedback");
  const [review, setReview] = useState("");
  const [score, setScore] = useState({});
  const [createdAt, setCreatedAt] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [skip, setSkip] = useState(1);
  const [total, setTotal] = useState(10);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await fetchFeedback(skip);
      setLoading(false);
    };
    getData();
  }, [fetchFeedback, type, skip]);

  useEffect(() => {
    if (feedback.totalCount) {
      setTotal(feedback.totalCount[0].count);
    }
  }, [feedback]);

  const openModal = (modalType, record) => {
    setIsModalVisible(true);
    setType(modalType);
    setReview(record.review);
    setCreatedAt(record.createdAt);
    setScore(record.score);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    setLoading(true);
    setIsModalVisible(false);
  };

  const handleCSV = async () => {
    try {
      setLoadingCSV(true);

      const response = await axios.get(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/reports/feedbacks`,
      );

      if (response) {
        const csvText = await response.data; 
        setCsvData(csvText);  
        csvLink.link.click(); 

        message.success("CSV exportado com sucesso!"); 
      } else {
        message.error("Erro ao buscar CSV.");  
      }
    
      setLoadingCSV(false); 
    } catch (error) {
      setLoadingCSV(false);
      console.error("Erro ao exportar CSV.", error);
      message.error("Ocorreu um erro ao tentar exportar o CSV.");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.user.localeCompare(b.user),
    },
    {
      title: "PPlan",
      dataIndex: "pplan",
      key: "pplan",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.pplan - b.pplan,
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.review.localeCompare(b.review),
    },
    {
      title: "Scores",
      dataIndex: "score",
      key: "score",
      sortDirections: ["ascend", "descend"],
      render: (_, record) => (
        <div>
          {record.score
            ? Object.keys(record.score).map((score) => {
                const numbScore = record.score[score];
                return (
                  <Button
                    style={{
                      marginRight: 5,
                      borderColor:
                        numbScore > 3
                          ? "#389e0d"
                          : numbScore < 4 && numbScore > 2
                            ? "#fa8c16"
                            : "#cf1322",
                      backgroundColor:
                        numbScore > 3
                          ? "#389e0d"
                          : numbScore < 4 && numbScore > 2
                            ? "#fa8c16"
                            : "#cf1322",
                      color: "#fff",
                    }}
                  >
                    {score}:{numbScore}
                  </Button>
                );
              })
            : null}
        </div>
      ),
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <Button
                type="primary"
                onClick={() => openModal("viewFeedback", record)}
              >
                View
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div>
      {formError ? (
        <div style={{ marginTop: 20 }}>
          {" "}
          <Alert message={formError} type="error" />{" "}
        </div>
      ) : (
        ""
      )}

      <div>
        <CSVLink
          data={csvData}
          filename="feedback.csv"
          className="hidden"
          ref={(link) => setCsvLink(link)}
        />

        <Button type="primary" size="middle" onClick={handleCSV} loading={loadingCSV}>
          {loadingCSV ? (
            <>Loading</>
          ) : (
            <>
              <SmileOutlined /> Exportar
            </>
          )}
        </Button>
      </div>

      <div style={{ marginTop: 20 }}>
        <Spin spinning={loading}>
          <Table columns={columns} dataSource={feedback.feedbacks} />
          <Pagination total={total} current={skip} onChange={setSkip} />
        </Spin>
      </div>

      <Modal
        title="View Feedback"
        visible={isModalVisible}
        onCancel={closeModal}
        onOk={handleOk}
        footer={[
          <Button key="back" onClick={closeModal}>
            Close
          </Button>,
        ]}
      >
        <ViewForm
          review={review}
          createdAt={createdAt}
          scores={
            score ? Object.keys(score).map((sc) => `${sc}:${score[sc]}`) : []
          }
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  feedback: state.feedback.feedback,
  searchError: state.tracks.searchError,
});

export default connect(mapStateToProps, { fetchFeedback })(Feedback);
